<script>
	import { slide } from 'svelte/transition';
	import Logo from '$lib/assets/images/logo-transparent.svg';
	import Icon from '$lib/assets/images/icon.svg';

	export let isCompact = false;
</script>

<div class="logo">
	<a href="/">
		{#if isCompact}
			<img src={Icon} alt="logo" width="48" height="48" in:slide|global={{ duration: 300 }} />
		{:else}
			<img src={Logo} alt="logo" width="160" height="104" />
		{/if}
	</a>
</div>

<style lang="scss">
	.logo {
		display: flex;

		a {
			height: 100%;
		}
	}
</style>
